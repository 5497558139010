.red-tooltip .tooltip > .tooltip-inner {background-color: #f00;}
.red-tooltip .tooltip > .arrow::before { border-right-color:#f00;border-left-color:#f00;}
.red-tooltip .tooltip { z-index:20;}


#parentDivOfGrid .rdg-cell-action-button{
    width:15px;
    margin-right: 5px;
  }

 #contract_follow_list .public_fixedDataTableCell_cellContent {
    padding: 0px;
}

.faceiconcontract{
    width: 25px;
    max-height: 25px;
    border-radius: 50%;
    margin:3px;
}
.faceiconcontract_houjin{
    width: 20px;
    max-height: 20px;
    border-radius: 50%;
    margin:3px;
}

#contract_follow_list .pink {
    background-color: pink;
}

#contract_follow_list .public_fixedDataTableRow_highlighted, .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: #f0f0f0;
}