#numberform{
  text-align: right;
  
}
#responsefileupload{
   -webkit-filter:drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
  -moz-filter:drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
  -ms-filter:drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
  filter:drop-shadow(1px 3px 5px rgba(0, 0, 0, 0.2));
}

.file .dropzone{
  background : white;
  width:100%;
  overflow: hidden;
  min-height: 100px;
  vertical-align: middle;


}