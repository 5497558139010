table.timeTable{
    border-collapse: collapse;
}
table.timeTable th, table.timeTable td {
    border:1px solid #BFB9B0;
    /*実線 1px 黒*/
    background-color: #ffffff;
    padding: 0px;
    color: #333333;
  }
.timeTable{
    width:775px;
}

.month-cell{
    text-align: center;
}

table.timeTable .time-cell{
    text-align: right;
    padding-right: 10px;
    background-color: #f6f6f6;
}
table.timeTable .time-cell-l{
    text-align: left;
    padding-left: 10px;
    background-color: #f6f6f6;
}
.np-cell{
    text-align: center;
    font-size: 12px;
    width:86px
}
.border1{
    border-top: 2px solid #BFB9B0;
}