.gridNormalList .public_fixedDataTableCell_main,
.gridNormalList .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: inherit;
    
}
.fixedDataTableRowLayout_body{
    background-color: inherit;
}
.fixedDataTableCellGroupLayout_cellGroupWrapper{
    background-color: inherit;
}
.fixedDataTableCellGroupLayout_cellGroup{
    background-color: inherit;
}

.gridNormalList .normal:hover  {
    background-color: #ffecb9
}

.gridNormalList .fixedDataTableCellGroupLayout_cellGroup:hover{
    background-color: #ffecb9
}
