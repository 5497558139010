.tenantimage .dropzone {
  background: #252525;
  width: 240px;
  overflow: hidden;
  min-height: 152px;
  vertical-align: middle;
 
  margin: 5px;
  text-align: center;
  padding:5px;
}

.contain {
  display: inline-block;
  background-color: #252525;
  background-position: center center;
  background-repeat: no-repeat;

  width: 150px;
  height: 150px;
  /* border: 1px solid #ccc; */
  background-size: contain;
}

div.react-images__blanket {
  z-index: 5000 !important;
}
div.react-images__positioner {
  z-index: 5000 !important;
}