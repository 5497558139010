.mypage_comment::-webkit-scrollbar{
    width: 4px;
    height: 4px;
}
.mypage_comment::-webkit-scrollbar-track{
    background: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: inset 0 0 2px #777; 
  }
.mypage_comment::-webkit-scrollbar-thumb{
    background: #ccc;
    border-radius: 10px;
    box-shadow: none;
}