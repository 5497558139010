#footer-menublock {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    background-color: rgba(255,255,255,0.9);
    z-index: 900;
}
#footer-menublock ul.footer-menu_list {
    height: 50px;
    border-top: 1px solid #c8c7cc;
    display: flex;
}
.pa-left-0 {
    padding-left: 0px !important;
}
ul, ol {
    margin-top: 0;
    margin-bottom: 10px;
}
#footer-menublock ul.footer-menu_list li {
    flex: auto;
    float: left;
    list-style: none;
    position: relative;
    text-align: center;
    padding-top: 5px;
}
#footer-menublock ul.footer-menu_list li a.current {
    color: #3BAFDA;
}
#footer-menublock ul.footer-menu_list li a {
    position: relative;
    display: inline-block;
    color: #979797;
}
#footer-menublock ul.footer-menu_list li i {
    display: block;
    font-size: 30px;
}
#footer-menublock a:hover{
    text-decoration: none;
}