#applicationFee{
  text-align: right;
  font-size: 16px;
  width:150px;
}

#contractSum{
  text-align: right;
  font-size: 20px;
  width:150px;
}

.MuiPopover-root{
  z-index:3000 !important;
}
.red-tooltip .tooltip > .tooltip-inner {background-color: #f00;}
.red-tooltip .tooltip > .arrow::before { border-right-color:#f00;border-left-color:#f00;}
 .tooltip { z-index:8000 !important;}
