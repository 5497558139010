
ul.list {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding-left: 0;
    margin-bottom: 0px;
}

    ul.list li {
        display: inline-block;
        width: 50%;
        margin-left: 5px;
        white-space: normal;
        vertical-align: top;
        margin-bottom: 0px;
        background-color: white;
        border-radius: 12px;
        width: 200px;
    }

.l_img {
    object-fit: cover;
    width: 200px;
    height: 150px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.l_title {
    padding: 10px;
    text-align: left;
}

.l_detail {
    padding: 10px;
    padding-top: 0px;
    font-size: 10px;
    height: 55px;
    text-align: left;
}

.l_menu1 {
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid #dcdcdc;
    font-weight: bold;
    text-align: center;
}

.l_menu2 {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    text-align: center;
}

.t_kakaku {
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid #dcdcdc;
    font-weight: bold;
}



#line #scroller {
    height: 90%;
    background: #7da4cd;
    overflow: auto;
}

#line .list-box {
    background: #7da4cd;
}

#line {
    background: #7da4cd;
}

#kanriseiyaku-box {
    background: #7da4cd;
}

#line .balloon6 .faceicon {
    float: left;
    margin-right: -50px;
    width: 40px;
}

    #line .balloon6 .faceicon img {
        width: 100%;
        height: auto;
        border-radius: 50%;
    }

#line .balloon6 .chatting {
    width: 100%;
    text-align: left;
}

#line .says {
    display: inline-block;
    position: relative;
    margin: 0 0 0 50px;
    padding: 10px;
    max-width: 250px;
    border-radius: 12px;
    background: #edf1ee;
    margin-bottom: 2px;
}

#line .time {
    display: inline-block;
    position: relative;
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 0px;
}


#line .says:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 3px;
    left: -19px;
    border: 8px solid transparent;
    border-right: 18px solid #edf1ee;
    -ms-transform: rotate(35deg);
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
}

#line .says p {
    margin: 0;
    padding: 0;
}




#line .balloon7 .chatting2 {
    width: 100%;
    text-align: right;
}

#line .says2 {
    display: inline-block;
    position: relative;
    margin: 0 0 0 60px;
    padding: 10px;
    max-width: 250px;
    border-radius: 12px;
    background: #7fff97;
    margin-bottom: 2px;
    text-align: left;
}

#line .time2 {
    display: inline-block;
    position: relative;
    margin: 0 0 0 0;
    position: absolute;
    bottom: 0px;
}

#line .says2:after {
    content: "";
    position: absolute;
    top: 3px;
    right: -19px;
    border: 8px solid transparent;
    border-left: 18px solid #7fff97;
    -ms-transform: rotate(-35deg);
    -webkit-transform: rotate(-35deg);
    transform: rotate(-35deg);
}
.item-line {
    border-radius: 10px;
    background: #7da4cd;
    padding: 15px;
    text-align: center;
    /* height: calc(100% - 50px); */
    overflow: auto;
    /* height: calc(100% - 60px); */
    -ms-overflow-style: none;    /* IE, Edge 対応 */
    scrollbar-width: none;       /* Firefox 対応 */
}
.item-line::-webkit-scrollbar {  /* Chrome, Safari 対応 */
    display:none;
}

.item-line2 {
    border-radius: 0px 0px 10px 10px;
    background: #7da4cd;
    padding: 15px;
    text-align: center;
    /* height: calc(100% - 50px); */
    overflow: auto;
    /* height: calc(100% - 60px); */
    -ms-overflow-style: none;    /* IE, Edge 対応 */
    scrollbar-width: none;       /* Firefox 対応 */
}
.item-line2::-webkit-scrollbar {  /* Chrome, Safari 対応 */
    display:none;
}

.linebottom{
    position: absolute;
    bottom:0;
    background: #FFF;
width:100%;
left: 0px;
}


.lineResponseBottom{
    position: absolute;
    bottom:10px;
    /* background: #FFF; */
/* width:100%; */
right: 10px;
}


.lineclose{
    position: absolute;
    bottom:10px;
    right: 10px;
    color: #696969;
font-size:20px;
margin:5px;

}

.line_mypage .MuiFormGroup-row{
    flex-direction: column
}