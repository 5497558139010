#mailheader{
  background:#333333;
  height:30px;
  color:#FFFFFF;
  padding:3px;
font-size:13px;

}
#mailheader span{
  margin:5px;
}
.edit{
  padding:5px;
}

.ql-toolbar.ql-snow {
  z-index: 1;
}


body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  /* top: 51px; */
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.container{
  
}
.dropzone{
  background : white;
  width:100%;
  overflow: hidden;
  min-height: 152px;
  vertical-align: middle;


}



.dropzone p{
  text-align:center;
  vertical-align: middle;
  color:gray;
  margin-bottom: 0;
}

.filename{
  list-style-type: none;
  background: #d3d3d3;
  width:250px;
  padding:5px;
  margin:3px;
}

.address{
  padding:5px;
  height:35px;
  border: 1px solid #eee;
}

.hoka{
  border: 1px solid #dcdcdc;
  background: #f5f5f5;
  margin-left:3px;
}

.templateGroup {
  list-style: none;
  padding:0;
  margin:0;
}
.templateGroupli {
  background-color: #dcdcdc;
  padding:8px 10px;
  margin-bottom:2px;
  font-weight: bold;
}

.templateList {
  list-style: none;
  padding-left: 12px;
  margin:0;
}
.templateListli {
  background-color: white;
  border-bottom:1px solid #f5f5f5;
  padding:6px 10px;
}
#mailDeleteTooltip{
  z-index: 3001;
}

.mail_tab .MuiTab-root{
  min-height:0px !important; 
}
/* 
.tooltip.show {
  z-index: 3001;
} */
 
