.responselist div[name="table"] .public_fixedDataTableCell_cellContent{
    padding:0
}
  
.responselist div[name="table"] {
    width: 100%;
}
  
.responselist .fixedDataTableCellLayout_wrap3{
    border-bottom: 1px solid#d3d3d3;
}

.responselist .fixedDataTableCellGroupLayout_cellGroupWrapper{
    border-bottom: 2px solid#bbbaba;
}
.responselist .fixedDataTableCellGroupLayout_cellGroup{
    border-bottom: 2px solid#bbbaba;
}
.responselist .fixedDataTableCellLayout_wrap3{
    text-align: center;
}


.responselist .public_fixedDataTableRow_highlighted, .responselist .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: white;
}

.responselist .in_table{
    border:0;
    table-layout: fixed;
    padding:0;
}

.responselist .in_table .th{
    padding:0px;
    height:33px;
    border-color:#d3d3d3;
    /* background-color: rgb(247, 247, 247); */
    border-width:0px 1px 1px 0px;
    white-space: nowrap;
    width: 80px;
    text-align: center;
    font-weight: bold;
}
.responselist .in_table .th.bottom{
    border-width:0px 1px 0px 0px;
}

.responselist .in_table td{
    padding:0px 5px;
    height:33px;
    border-color:#d3d3d3;
    border-width:0px 0px 1px 0px;
    white-space: nowrap;
}

.responselist .in_table td.bottom{
    padding:0px 5px;
    height:33px;
    border-color:#d3d3d3;
    border-width:0px 0px 0px 0px;
    white-space: nowrap;
}

.responselist .badge {
    margin-right: 2px;
    font-size: 85%;
}
.responselist .lightblue {
    background-color: #afeeee;
}
.responselist .pink {
    background-color: pink;
}
.responselist .yellow {
    background-color: #ffe6b3;
}
.responselist .green {
    background-color: #c8fac8 ;
}
.responselist .yellowgreen {
    background-color: #d7ffb3 ;
}
.responselist .purple {
    background-color: #dccffa ;
}
.responselist .orange {
    background-color: #fcf9a6 ;
}
.responselist .blue {
    background-color: #c4ddff ;
}
.responselist .red {
    background-color: #ffb8b8 ;
}

.responselist .white {
    background-color: white ;
}
.responselist .gray {
    background-color: #e2dfdf ;
}

.responselist .title {
    background: #f5f5f5;
    padding: 2px;
    font-weight: bold;
}

.responselist .faceicon {
    margin: 0px 3px;
}


.gridSendLineList .public_fixedDataTableCell_main,
.gridSendLineList .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: inherit;
}

.gridSendLineList .line_schedule_selected{
    background-color: rgb(252, 243, 225);
}


.gridSendLineList2 .public_fixedDataTableCell_main,
.gridSendLineList2 .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: inherit;
}

.gridSendLineList2 .line_schedule_zumi{
    background-color: rgb(168, 168, 168);
}


.gridLineTemplateList .public_fixedDataTableCell_main,
.gridLineTemplateList .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: inherit;
}

.gridLineTemplateList .line_template_selected{
    background-color: rgb(252, 243, 225);
}



.tooltip3{
    position: relative;
    cursor: pointer;
    display: inline-block;
}
.tooltip3 p{
    margin:0;
    padding:0;
}
.description3 {
    display: none;
    position: absolute;
    padding: 10px;
    font-size: 12px;
    line-height: 1.6em;
    border-radius: 5px;
    background: white;
    width: 150px;
    z-index: 100;
    border: 1px solid #d3d3d3;
}

.tooltip3:hover .description3{
    display: inline-block;
    top: 40px;
    right: 0px;
}
.irosetumei {
    padding:2px;
    color: black;
    margin: 2px;
}