#proposing-items-navi {
    background-color: #FFFFFF;
}

#proposing-items-navi .nav-tabs li {
    width:30%;
    height:45px;
    text-align: center;    
    border-top: #CCD1D9 4px solid !important;
    border-right: #CCD1D9 1px solid; 
}
#proposing-items-navi .nav-tabs li.active{
    border-top: #3BAFDA 4px solid
}
#proposing-items-navi .nav-tabs li a {
    padding-left: 0;
    padding-right: 0;
    background-color: white;
    color: #555555
}