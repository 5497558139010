.tuikyaku_status .css-acap47-MuiAlert-message{
    padding:2px;
    font-size: 13px;
}

.circle_number {
    background: #9e9e9e;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: #fff;
    font-size: 11px;
    text-align: center;
    line-height: 20px;
  }