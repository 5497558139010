.dateCell{
    /* border: solid #BFB9B0; */
    /* border-width: 1px 0 2px 1px; */
    box-sizing: border-box;
    height: 45px;
    text-align: center;
    width: 42px;
    background-color: #ffffff;
    color: #333333;
    font-size: 12px;
}
.dateCell span{
    display: block;
    text-align:center;
    font-weight: bolder;    
}
.sat {
    background-color: #D9E3EC;
    color: #4466BB;
}
.sun {
    background-color: #EEDDD9;
    color: #BB5555;
}