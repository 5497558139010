.gridCorporateNormalList .public_fixedDataTableCell_main,
.gridCorporateNormalList .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: inherit;
    
}
.fixedDataTableRowLayout_body{
    background-color: inherit;
}
.fixedDataTableCellGroupLayout_cellGroupWrapper{
    background-color: inherit;
}
.fixedDataTableCellGroupLayout_cellGroup{
    background-color: inherit;
}

.gridCorporateNormalList .check  {
    background-color: #ffa9d8
}

.gridCorporateNormalList .check:hover  {
    background-color: #fa88c7
}

.gridCorporateNormalList .normal:hover  {
    background-color: #ffecb9
}
