/*a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}*/


#root{
    height:100%;
}

html, body {
    height: 100%;
    margin: 0;
    font-size: 14px;
}

.app {
    height: 100%;
    display: grid;
    grid-template-rows: 40px 1fr;
    grid-template-columns: 50px 1fr;
    background: #E9F2F8;
}

header {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .16);
    /* グリッド ラインの1から始まり、最後まで占める */
    grid-column: 1/-1;
    background-color: #4682B4;
    border-color: #e7e7e7;
    color: #FFFFFF;
}

    header a {
        color: #FFFFFF;
    }

.navbar-brand {
    position: absolute;
    left: 10px;
    top: -2px;
    padding:2px !important;
    font-size: 28px !important;
}

.navbar-menu {
    font-size: 16px;
    cursor: pointer;
    padding: 10px 20px;
    width:58px;
    margin-top:0px;
}
    .navbar-menu:hover {
        color: white;
        background-color: #6d94b5;
        text-decoration: none;
        width: 58px;
    }

.navbar-menu-sub {
    cursor: pointer;
    padding: 10px 20px;
    margin-top: 0px;
}

    .navbar-menu-sub:hover {
        text-decoration: none;
    }





#aside {
    z-index: 1000;
    background-color: #6d94b5;
}

#container {
    overflow-y: scroll;
  
}


.drawer-nav {
    z-index: 1500;
}

.grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    padding: 5px;
}

.item {
    border-radius: 10px;
    background: #FFFFFF;
    padding: 15px;
    text-align: center;
    height: calc(100% - 50px);
    overflow: auto;
    height: 100%;
}
.itemabsolute{
    border-radius: 10px;
    background: #FFFFFF;
    padding: 15px;
    text-align: center;
    height: calc(100% - 50px);
    /* overflow: auto; */
    height: 100%; 
}

.item-outer-outer{
    margin:0;
    padding:0;
}

.item-outer {
    padding: 5px;
}

img {
    max-width: 100%;
    height: auto;
}

p {
    text-align: left;
}

.row {
    margin-right: 0px;
    margin-left: 0px;
}






#btn-hamburger {
    margin-top: 20px;
    margin-left: -2px;
    color:white;

}
#btn-hamburger:focus {
	outline:0;
}

@media screen and (max-width: 736px) {
    #btn-hamburger {
        margin-top: 40px;
        margin-left: 5px;
    }
}

.drawer-hamburger-icon {
    margin-top: 20px;
    margin-left: 5px;
    width:20px;
}


.drawer-hamburger-icon, .drawer-hamburger-icon:after, .drawer-hamburger-icon:before {
    background-color: white;
}


.homestation-menu::-webkit-scrollbar {
    display: none;
  }




.left-side-menu {
    color: #ffffff;
    font-size: 22px;
    position: relative;
    display: block;
    padding-left: 11px;
    padding-right: 15px;
    padding-top: 1px;
    padding-bottom: 1px;
}
.left-side-menu:hover {
    background-color: #f5f5f5;
    text-decoration: none;
    color: #ffffff;
}
.left-side-icon {
    color: #ffffff;
    font-size:22px;
}

.left-side-list-menu {
    color: #6d94b5;
    font-size: 22px;
    position: relative;
    display: block;
    padding-left: 11px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
}
.left-side-list-menu:hover {
    background-color: #e7e7e7;;
    text-decoration: none;
    color: #6d94b5;
}
.left-side-list-icon {
    font-size:22px;
}

.left-side-list-group {
    font-size:15px;
    color:black;
    margin-left: 13px;
}



#mail {
    position: fixed;	/* 要素の位置を固定する */
    bottom: 0;	/* 基準の位置を画面の一番下に指定する */
    right: 30px;	/* 基準の位置を画面の一番右に指定する */
    width: 500px;	/* 幅を指定する */
    height: 530px;	/* 幅を指定する */
z-index:2000;
background:#FFFFFF;
    filter: drop-shadow(10px 10px 10px rgba(0,0,0,0.6));
    }

    #mailminimum {
        position: fixed;	/* 要素の位置を固定する */
        bottom: -5px;	/* 基準の位置を画面の一番下に指定する */
        right: 30px;	/* 基準の位置を画面の一番右に指定する */
        width: 300px;	/* 幅を指定する*/
        height: 50px;	/*幅を指定する */
    z-index:2000;
    padding:15px;
    border-radius: 10px;
    background:#333;
    color:#FFF;
        filter: drop-shadow(10px 10px 10px rgba(0,0,0,0.6));
        }
  
    
    #line{
        position: fixed;	/* 要素の位置を固定する */
        bottom: 0;	/* 基準の位置を画面の一番下に指定する */
        right: 30px;	/* 基準の位置を画面の一番右に指定する */
        width: 500px;	/* 幅を指定する */
        height: 90%;	/* 幅を指定する */
    z-index:2000;
    background:#FFFFFF;
        filter: drop-shadow(10px 10px 10px rgba(0,0,0,0.6));
        border-radius: 10px;
        }
      