
.border-bottoms>div>div {
    border-bottom:2px solid #a3a0a0;
}
.filesgrid .rdg-cell-action-last {
    margin-right: 5px;
    margin-top: 3px;
}
/* .react-grid-HeaderCell {
    padding: 0px;
} */
.react-grid-Cell {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    text-align:left;
}
.faceicon{
    width: 30px;
    max-height: 30px;
    border-radius: 50%;
    margin:3px;
}
.react-grid-Grid{
 
    background-color: "#ddd";
}
.rdg-editor-container { z-index:10050 !important;
    /* position:absolute !important; */
    }

    
/* .react-grid-Canvas { position:relative !important; } */
/* .react-grid-Cell{ z-index: 10050  } */

.row-highlight-orange>div>div{ background-color:#ffe6b3 !important; }


.row-highlight-pink>div>div{ background-color:pink !important; }

.row-highlight-green>div>div{ background-color:#c8fac8 !important; }

.row-highlight-white>div>div{ background-color:#FFF !important; }
.row-highlight-gray>div>div{ background-color:#d3d3d3 !important; }

.row-highlight-yellow>div>div{ background-color:#ffe6b3 !important; }


.row-highlight-yellowgreen>div>div{ background-color:#d7ffb3 !important; }


.gridMailTemplateSearchList .public_fixedDataTableCell_main,
.gridMailTemplateSearchList .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: inherit;
    
}
.fixedDataTableRowLayout_body{
    background-color: inherit;
}
.fixedDataTableCellGroupLayout_cellGroupWrapper{
    background-color: inherit;
}
.fixedDataTableCellGroupLayout_cellGroup{
    background-color: inherit;
}

.gridMailTemplateSearchList .normal:hover  {
    background-color: #c8fac8
}

.gridMailTemplateSearchList .select  {
    background-color: #c8fac8
}

.dragging {
    background-color: #fff7b1;
  }