/* .container {
    max-width: 100%;
  } */

  .showRoomList .public_fixedDataTableCell_cellContent{
    padding-top:0;
    padding-bottom:0;

  }

  .parkingList .public_fixedDataTableCell_cellContent{
    padding-top:0;
    padding-bottom:0;

  }




.gridMapSearchRoomList .public_fixedDataTableCell_cellContent{
  padding: 0;

}

.gridMapSearchRoomList .public_fixedDataTableCell_main,
.gridMapSearchRoomList .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: inherit;
    
}
.gridMapSearchRoomList .fixedDataTableRowLayout_body{
    background-color: inherit;
}
.gridMapSearchRoomList .fixedDataTableCellGroupLayout_cellGroupWrapper{
    background-color: inherit;
}
.gridMapSearchRoomList .fixedDataTableCellGroupLayout_cellGroup{
    background-color: inherit;
    border-bottom: 1px solid #d3d3d3;
    text-align: center;
    padding:0px;
}

.gridMapSearchRoomList .nyukyo:hover  {
    background-color: #919191
}
.gridMapSearchRoomList .nyukyo  {
    background-color: #cac9c9
}

.gridMapSearchRoomList .normal  {
  background-color: #ffffff
}

.gridMapSearchRoomList .normal:hover  {
    background-color: #ffecb9
}
