.cv-spinner {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px #ddd solid;
    border-top: 4px #2e93e6 solid;
    border-radius: 50%;
    animation: sp-anime 0.8s infinite linear;
}

@keyframes sp-anime {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

#overlay {
    display: none;
    width: 100%;
    height: 100%;
    text-align: center;
    position: fixed;
    top: 0;
    z-index: 1500;
    background: rgba(0,0,0,0.7);
}
