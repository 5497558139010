

.visit_search_dialog .MuiDialog-paper{
    margin: 0px !important; 
}



.visit_search_dialog .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthFalse.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded{
    max-height: none !important; 
}